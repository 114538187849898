import React from "react";
import moment from "moment";

import DateBoxOption from "../../ui/ui-components/date-box-option";

const TimeField = (
  {
    columnDef,
    value,
    onChange,
    locale,
    forwardedRef,
    ...rest
  }
) => {
  const getProps = () => {
    const {
      columnDef,
      rowData,
      onRowDataChange,
      errorState,
      onBulkEditRowChanged,
      scrollWidth,
      ...remaining
    } = rest;
    return remaining;
  };

  const dateFormat =
    columnDef.dateSetting && columnDef.dateSetting.format
      ? columnDef.dateSetting.format
      : "HH:mm:ss";

  const dateValueType = "moment";

  const isUtc =
    columnDef.dateSetting && columnDef.dateSetting.isUtc
      ? columnDef.dateSetting.isUtc
      : false;

  const datePickerProps = getProps();

  const handleChange = date => {
    console.log(date);
    if (!date) return;
    if (date && date.target) {
      date = date.target.value;
    }
    const ts = date ? Math.floor(date.valueOf()) : null;
    if (isNaN(ts) || ts === null) {
      return;
    }

    onChange(moment(ts));
  }

  return (
    <DateBoxOption
      {...datePickerProps}
      ref={forwardedRef}
      className={dateFormat.length > 10 ? "minw-200p" : ""}
      format={dateFormat}
      name={columnDef.field}
      value={!isNaN(value) ? moment(value) : null}
      onChange={handleChange}
      type="time"
      valueType={dateValueType}
      isUTC={isUtc}
      label={columnDef.title}
    />
  );
}

const TimeFieldRef = React.forwardRef((props, ref) => {
  return <TimeField {...props} forwardedRef={ref} />;
});

export default TimeFieldRef;
