import { userConstants } from "./authConstants";
import { authService } from "./authServices";
import axios from "src/store/_shared/authAxios";

import { doCasinoAuthentication } from "../../actions/casino";
import { tenantsFetchAll } from "../../actions/tenants";
import { sitesFetchAll } from "src/store/actions/sites";
import { loadSelects } from "../../actions/selects";
import { loadLotteries } from "../../actions/lotteries";
import { loadGames } from "../../actions/games";
import { loadTables } from "../../actions/live-casino";
import { dataTypesDefRequest } from "../../actions/data";
import {
  contentManagementTypesDefRequest,
  contentManagementAttributesListRequest,
} from "src/store/actions/content-management";
import { loadCountryFlags } from "src/store/actions/country-flags";

export const userActions = {
  login,
  logout,
  check,
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    return authService.login(username, password).then(
      data => {
        dispatch(success(data));
        dispatch(tenantsFetchAll());
        dispatch(sitesFetchAll());
        dispatch(doCasinoAuthentication());
        dispatch(loadSelects());
        dispatch(loadGames());
        dispatch(loadLotteries());
        dispatch(loadTables());
        dispatch(dataTypesDefRequest());
        dispatch(contentManagementTypesDefRequest());
        dispatch(contentManagementAttributesListRequest());
        dispatch(loadCountryFlags());
      },
      error => {
        dispatch(failure(error.toString()));
        throw error;
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function check(cb) {
  return dispatch => {
    dispatch(request());

    return userService.check().then(
      data => {
        dispatch(success(data));
        if (typeof cb === "function") cb(true);
      },
      error => {
        dispatch(failure(error.toString()));
        if (typeof cb === "function") cb(true, false);
      }
    );
  };

  function request() {
    return { type: userConstants.AUTH_CHECK_REQUEST };
  }
  function success(data) {
    return { type: userConstants.AUTH_CHECK_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.AUTH_CHECK_FAILURE, error };
  }
}

function logout(cb) {
  return dispatch => {
    return authService.logout().then(
      () => {
        dispatch(request());
        if (typeof cb === "function") cb(true);
      },
      () => {
        dispatch(request());
        if (typeof cb === "function") cb(true, false);
      }
    );
  };

  function request() {
    return { type: userConstants.LOGOUT };
  }
}
