import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import {getCountryCallingCode} from "react-phone-number-input/input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const mapStateToProps = state => {
  return {
    countryFlagImages: state.countryFlags.images,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  classes: {
    root: "country-switcher-menu-modal",
    paper: "country-switcher-menu-container",
    list: "country-switcher-menu-list",
  }
};

export const CountrySelect = (props) => {
  const {
    value,
    onChange,
    options,
    disabled,
    readOnly,
    unicodeFlags,
    iconComponent: Icon,
    arrowComponent: Arrow,
    countryFlagImages,
    ...rest
  } = props;

  const onChange_ = React.useCallback((event) => {
    const value = event.target.value;
    onChange(value === 'ZZ' ? undefined : value);
  }, [onChange]);

  const selectedOption = React.useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  // console.log("countryFlagImages", countryFlagImages);

  // "ZZ" means "International".
  // (HTML requires each `<option/>` have some string `value`).
  return (
    <Select
      className="country-switcher"
      labelId="country-switcher-select-helper-label"
      id="country-switcher-simple-select-helper"
      value={value || 'ZZ'}
      onChange={onChange_}
      title={"Change Country"}
      IconComponent={() => null}
      inputProps={{ "aria-label": "Without label" }}
      displayEmpty
      MenuProps={{...MenuProps}}
      renderValue={(value) => {
        if (value === 'ZZ') return <div></div>;
        const phoneCode = value ? getCountryCallingCode(value) : null;
        const Image = countryFlagImages?.find(image => image.countryCode.toUpperCase() === value.toUpperCase())?.img ?? null;
        return (<div>
          {value !== 'ZZ' && <div aria-hidden="true" className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
            {!Image && <img
              className="PhoneInputCountryIconImg"
              alt={`${value}`}
              src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${value.toLowerCase()}.svg`}
              referrerPolicy="no-referrer"
            />}
            {!!Image && <img
              alt={`${value}`}
              className="PhoneInputCountryIconImg"
              src={Image.src}
              referrerPolicy="no-referrer"
            />}
          </div>}
          {value && <>&ensp;{selectedOption?.value}{phoneCode && <> (+{phoneCode})</>}&ensp;</>}
          <Arrow/>
        </div>);
      }}
    >
      {options
        .sort(({value: valueA}, {value: valueB}) => {
          if (valueA === 'ZZ' || !valueA) return 0;
          if (valueB === 'ZZ' || !valueB) return -1;
          const diff = valueA.localeCompare(valueB);
          return diff > 0 ? 1 : diff < 0 ? -1 : 0;
        })
        .map(({value, label, divider}) => {
        const phoneCode = value ? getCountryCallingCode(value) : undefined;
        const Image = value ? countryFlagImages?.find(image => image.countryCode.toUpperCase() === value.toUpperCase())?.img ?? null : null;
        return (
          <MenuItem
            key={divider ? '|' : value || 'ZZ'}
            value={divider ? '|' : value || 'ZZ'}
            disabled={!value}
            style={divider ? DIVIDER_STYLE : undefined}
          >
            {!!value && <>
              <div aria-hidden="true" className="PhoneInputCountryIcon">
                {!Image && <img
                  className="PhoneInputCountryIconImg"
                  alt={`${label}`}
                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${value.toLowerCase()}.svg`}
                  referrerPolicy="no-referrer"
                />}
                {!!Image && <img
                  className="PhoneInputCountryIconImg"
                  alt={`${label}`}
                  src={Image.src}
                  referrerPolicy="no-referrer"
                />}
              </div>
              <div>&emsp;{value}</div>
              {phoneCode && <div>&emsp;(+{phoneCode})</div>}
            </>}
          </MenuItem>
        );
      })}
    </Select>
  );
};

CountrySelect.propTypes = {
  /**
   * A two-letter country code.
   * Example: "US", "RU", etc.
   */
  value: PropTypes.string,

  /**
   * A function of `value: string`.
   * Updates the `value` property.
   */
  onChange: PropTypes.func.isRequired,

  // `<select/>` options.
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    divider: PropTypes.bool,
  })).isRequired,

  // `readonly` attribute doesn't work on a `<select/>`.
  // https://github.com/catamphetamine/react-phone-number-input/issues/419#issuecomment-1764384480
  // https://www.delftstack.com/howto/html/html-select-readonly/
  // To work around that, if `readOnly: true` property is passed
  // to this component, it behaves analogous to `disabled: true`.
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const DIVIDER_STYLE = {
  fontSize: '1px',
  backgroundColor: 'currentColor',
  color: 'inherit',
};

const CountrySelectWithIcon = (props) => {
  const {
    value,
    options,
    className,
    iconComponent: Icon,
    getIconAspectRatio,
    arrowComponent: Arrow = DefaultArrowComponent,
    unicodeFlags,
    ...rest
  } = props;
  const selectedOption = React.useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  return (
    <div className="PhoneInputCountry">
      <CountrySelect
        {...rest}
        value={value}
        options={options}
        className={classNames('PhoneInputCountrySelect', className)}
        iconComponent={Icon}
        getIconAspectRatio
        arrowComponent={Arrow}
        unicodeFlags
      />
    </div>
  );
};

CountrySelectWithIcon.propTypes = {
  // Country flag component.
  iconComponent: PropTypes.elementType,

  // Select arrow component.
  arrowComponent: PropTypes.elementType,

  // Set to `true` to render Unicode flag icons instead of SVG images.
  unicodeFlags: PropTypes.bool
};

const DefaultArrowComponent = () => {
  return <div className="PhoneInputCountrySelectArrow"/>;
};

const getSelectedOption = (options, value) => {
  for (const option of options) {
    if (!option.divider) {
      if (isSameOptionValue(option.value, value)) {
        return option;
      }
    }
  }
};

const isSameOptionValue = (value1, value2) => {
  // `undefined` is identical to `null`: both mean "no country selected".
  if (value1 === undefined || value1 === null) {
    return value2 === undefined || value2 === null;
  }
  return value1 === value2;
};

export default connect(mapStateToProps)(CountrySelectWithIcon);